import ko from 'knockout';
import uuid from 'uuid/v4';
import { CaseRow } from "./caserow";

export function AEMCase(aemcase) {
  var self = this;

  self.ID = uuid();
  self.Name = aemcase.Name;
  self.Rows = aemcase.Rows;
  self.Units = aemcase.Units;
  self.Price = aemcase.Price;
  self.CablePrice = aemcase.CablePrice;
  self.Material =  ko.observable(aemcase.Material);
  self.Content = ko.observableArray();
  self.Order = ko.observable();

  for (var i = 0; i < aemcase.Rows; i++) {
    self.Content.push(new CaseRow(aemcase));
  }
}

export const CaseMaterial = {
  ACRYLIC_RED: {Name:'Red Acrylic', Class:'acrylic-red'},
  ACRYLIC_ORANGE: {Name:'Orange Acrylic', Class:'acrylic-orange'},
  ACRYLIC_PINK: {Name:'Pink Acrylic', Class:'acrylic-pink'},
  ACRYLIC_BLUE: {Name:'Blue Acrylic', Class:'acrylic-blue'},
  ACRYLIC_NEONGREEN: {Name:'Neon Green Acrylic', Class:'acrylic-neongreen'},
  ACRYLIC_DARKVIOLETSEMITRANSPARENT: {Name:'Dark Violet (semi-transparent)', Class:'acrylic-darkvioletsemitransparent'},
  ACRYLIC_NEONORANGESEMITRANSPARENT: {Name:'Neon Orange (semi-transparent)', Class:'acrylic-neonorangesemitransparent'},
  ACRYLIC_VIOLETSEMITRANSPARENT: {Name:'Violet (semi-transparent)', Class:'acrylic-violetsemitransparent'},
  ACRYLIC_YELLOW: {Name:'Yellow Acrylic', Class:'acrylic-yellow'},
  ACRYLIC_MATTEBLACK: {Name:'Matte Black Acrylic', Class:'acrylic-matteblack'},
  BAMBOO: {Name:'Bamboo', Class:'bamboo'},
  WALNUT: {Name:'Walnut', Class:'walnut'},
}