import { CaseMaterial } from '../modules/aemcase';

export var Cases = [

  // 1 row
  {
    Name: 'AE modular case 1-Row 12x1 NARROW',
    Rows: 1,
    Units: 12,
    Price: 23,
    CablePrice: 11,
    Material: CaseMaterial.ACRYLIC_RED
  },
  {
    Name: 'AE modular case 1-Row 16x1 STANDARD',
    Rows: 1,
    Units: 16,
    Price: 26,
    CablePrice: 13,
    Material: CaseMaterial.ACRYLIC_RED
  },
  {
    Name: 'AE modular case 1-Row 20x1 WIDE',
    Rows: 1,
    Units: 20,
    Price: 31,
    CablePrice: 16,
    Material: CaseMaterial.ACRYLIC_RED
  },

  // 2 rows
  {
    Name: 'AE modular case 2-Row 12x2 NARROW',
    Rows: 2,
    Units: 12,
    Price: 30,
    CablePrice: 22,
    Material: CaseMaterial.ACRYLIC_RED
  },
  {
    Name: 'AE modular case 2-Row 16x2 STANDARD',
    Rows: 2,
    Units: 16,
    Price: 35,
    CablePrice: 26,
    Material: CaseMaterial.ACRYLIC_RED
  },
  {
    Name: 'AE modular case 2-Row 20x2 WIDE',
    Rows: 2,
    Units: 20,
    Price: 41,
    CablePrice: 32,
    Material: CaseMaterial.ACRYLIC_RED
  },

  // 4 Rows 
  {
    Name: 'AE modular case 4-Row 20x4 WIDE',
    Rows: 4,
    Units: 20,
    Price: 51,
    CablePrice: 64,
    Material: CaseMaterial.ACRYLIC_RED
  },

  // Eurorack
  {
    Name: 'AE modular Eurorack adapter frame 8U',
    Rows: 1,
    Units: 8,
    Price: 20,
    CablePrice: 11,
    Material: CaseMaterial.ACRYLIC_RED
  },
  {
    Name: 'AE modular Eurorack adapter frame 12U',
    Rows: 1,
    Units: 12,
    Price: 24,
    CablePrice: 13,
    Material: CaseMaterial.ACRYLIC_RED
  },
  {
    Name: 'AE modular Eurorack adapter frame 16U',
    Rows: 1,
    Units: 16,
    Price: 28,
    CablePrice: 16,
    Material: CaseMaterial.ACRYLIC_RED
  }
];

export function GetCase(rows, units) {
  for (let i = 0; i < Cases.length; i++) {
    const aemCase = Cases[i];
    if (aemCase.Rows === rows && aemCase.Units === units) {
      return aemCase;
    }
  }

  return null;
}