export var BlankModule = {
  ModuleID: 0,
  Name: "BLANK",
  Units: 1,
  Image: 'images/modules/blank.png',
  Discontinued: false
};

export var Modules = [
  {
    Name: "MASTER",
    ModuleID: 1,
    Units: 2,
    Image: 'images/modules/master.png',
    Discontinued: true,
    WikiLink: 'https:////wiki.aemodular.com/pmwiki.php/AeManual/MASTER',
    Categories: ['IO'],
    PowerConsumption: 30,
    PowerCapacity: 800,
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "2OSC/d",
    ModuleID: 2,
    Units: 1,
    Image: 'images/modules/2oscd.png',
    Discontinued: false,
    WikiLink: 'https:////wiki.aemodular.com/pmwiki.php/AeManual/2OSCD',
    Categories: ['SOUND', 'CV'],
    Price: 38,
    PowerConsumption: 25,
    PowerCapacity: 0,
    ShopLink: "https://www.tangiblewaves.com/store/p60/2OSC%2Fd.html",
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "VCO",
    ModuleID: 3,
    Units: 1,
    Image: 'images/modules/vco.png',
    Discontinued: false,
    WikiLink: 'https:////wiki.aemodular.com/pmwiki.php/AeManual/VCO',
    Categories: ['SOUND', 'CV'],
    Price: 32,
    PowerConsumption: 16,
    PowerCapacity: 0,
    ShopLink: "https://www.tangiblewaves.com/store/p6/VCO_.html",
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "NOISE",
    ModuleID: 4,
    Units: 1,
    Image: 'images/modules/noise.png',
    Discontinued: true,
    WikiLink: 'https:////wiki.aemodular.com/pmwiki.php/AeManual/NOISE',
    Categories: ['SOUND', 'CV'],
    PowerConsumption: 5,
    PowerCapacity: 0,
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "WAVEFOLDER",
    ModuleID: 5,
    Units: 1,
    Image: 'images/modules/wavefolder.png',
    Discontinued: false,
    WikiLink: 'https:////wiki.aemodular.com/pmwiki.php/AeManual/WAVEFOLDER',
    Categories: ['EFFECT', 'CV'],
    Price: 26,
    PowerConsumption: 3,
    PowerCapacity: 0,
    ShopLink: "https://www.tangiblewaves.com/store/p45/WAVEFOLDER.html",
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "LFO",
    ModuleID: 6,
    Units: 1,
    Image: 'images/modules/lfo.png',
    Discontinued: true,
    WikiLink: 'https:////wiki.aemodular.com/pmwiki.php/AeManual/LFO',
    Categories: ['CV'],
    Price: 23,
    PowerConsumption: 3,
    PowerCapacity: 0,
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "2ENV",
    ModuleID: 7,
    Units: 1,
    Image: 'images/modules/2env.png',
    Discontinued: false,
    WikiLink: 'https:////wiki.aemodular.com/pmwiki.php/AeManual/2ENV',
    Categories: ['CV'],
    Price: 36,
    PowerConsumption: 8,
    PowerCapacity: 0,
    ShopLink: "https://www.tangiblewaves.com/store/p59/2ENV.html",
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "FILTER (WASP TYPE)",
    ModuleID: 8,
    Units: 1,
    Image: 'images/modules/wasp.png',
    Discontinued: false,
    WikiLink: 'https:////wiki.aemodular.com/pmwiki.php/AeManual/FILTERWASP',
    Categories: ['FILTER'],
    Price: 31,
    PowerConsumption: 3,
    PowerCapacity: 0,
    ShopLink: "https://www.tangiblewaves.com/store/p9/WASP_FILTER.html",
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "NYLE FILTER (STEINER TYPE)",
    ModuleID: 9,
    Units: 2,
    Image: 'images/modules/nyle.png',
    Discontinued: true,
    WikiLink: 'https:////wiki.aemodular.com/pmwiki.php/AeManual/NYLEFILTER',
    Categories: ['FILTER'],
    PowerConsumption: 2,
    PowerCapacity: 0,
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "SVFILTER",
    ModuleID: 10,
    Units: 1,
    Image: 'images/modules/svfilter.png',
    Discontinued: false,
    WikiLink: 'https:////wiki.aemodular.com/pmwiki.php/AeManual/SVFILTER',
    Categories: ['FILTER'],
    Price: 30,
    PowerConsumption: 2,
    PowerCapacity: 0,
    ShopLink: "https://www.tangiblewaves.com/store/p61/SVFILTER_.html",
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "LOPAG (LOWPASS GATE)",
    ModuleID: 11,
    Units: 1,
    Image: 'images/modules/lopag.png',
    Discontinued: false,
    WikiLink: 'https:////wiki.aemodular.com/pmwiki.php/AeManual/LOPAG',
    Categories: ['FILTER'],
    Price: 28,
    PowerConsumption: 1,
    PowerCapacity: 0,
    ShopLink: "https://www.tangiblewaves.com/store/p62/LOPAG_%28lowpass_gate%29.html",
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "2VCA",
    ModuleID: 12,
    Units: 1,
    Image: 'images/modules/2vca.png',
    Discontinued: false,
    WikiLink: 'https:////wiki.aemodular.com/pmwiki.php/AeManual/2VCA',
    Categories: ['UTILITY'],
    Price: 23,
    PowerConsumption: 5,
    PowerCapacity: 0,
    ShopLink: "https://www.tangiblewaves.com/store/p11/2VCA_.html",
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "MIXER 4-4",
    ModuleID: 13,
    Units: 1,
    Image: 'images/modules/mixer4x4.png',
    Discontinued: false,
    WikiLink: 'https:////wiki.aemodular.com/pmwiki.php/AeManual/MIXER44',
    Categories: ['UTILITY'],
    Price: 21,
    PowerConsumption: 5,
    PowerCapacity: 0,
    ShopLink: "https://www.tangiblewaves.com/store/p13/MIXER_4-4.html",
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "2ATT/CV",
    ModuleID: 14,
    Units: 1,
    Image: 'images/modules/2attcv.png',
    Discontinued: false,
    WikiLink: 'https:////wiki.aemodular.com/pmwiki.php/AeManual/2ATTCV',
    Categories: ['UTILITY'],
    Price: 22,
    PowerConsumption: 4,
    PowerCapacity: 0,
    ShopLink: "https://www.tangiblewaves.com/store/p15/2ATT%2FCV_.html",
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "4ATTMIX",
    ModuleID: 15,
    Units: 1,
    Image: 'images/modules/4attmix.png',
    Discontinued: false,
    WikiLink: 'https:////wiki.aemodular.com/pmwiki.php/AeManual/4ATTMIX',
    Categories: ['UTILITY'],
    Price: 27,
    PowerConsumption: 2,
    PowerCapacity: 0,
    ShopLink: "https://www.tangiblewaves.com/store/p53/4ATTMIX.html",
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "SAMPLE & HOLD",
    ModuleID: 16,
    Units: 1,
    Image: 'images/modules/sh.png',
    Discontinued: false,
    WikiLink: 'https:////wiki.aemodular.com/pmwiki.php/AeManual/SAMPLEHOLD',
    Categories: ['UTILITY', 'CV'],
    Price: 22,
    PowerConsumption: 2,
    PowerCapacity: 0,
    ShopLink: "https://www.tangiblewaves.com/store/p18/SAMPLE%26HOLD.html",
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "LOGIC",
    ModuleID: 17,
    Units: 1,
    Image: 'images/modules/logic.png',
    Discontinued: false,
    WikiLink: 'https:////wiki.aemodular.com/pmwiki.php/AeManual/LOGIC',
    Categories: ['TRIG'],
    Price: 19,
    PowerConsumption: 1,
    PowerCapacity: 0,
    ShopLink: "https://www.tangiblewaves.com/store/p16/LOGIC.html",
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "3CVSWITCH",
    ModuleID: 18,
    Units: 1,
    Image: 'images/modules/3vcswitch.png',
    Discontinued: false,
    WikiLink: 'https:////wiki.aemodular.com/pmwiki.php/AeManual/3VCSWITCH',
    Categories: ['UTILITY'],
    Price: 29,
    PowerConsumption: 4,
    PowerCapacity: 0,
    ShopLink: "https://www.tangiblewaves.com/store/p42/3VCSWITCH.html",
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "BEAT DIVIDER",
    ModuleID: 19,
    Units: 1,
    Image: 'images/modules/beatdivider.png',
    Discontinued: false,
    WikiLink: 'https:////wiki.aemodular.com/pmwiki.php/AeManual/BEATDIVIDER',
    Categories: ['TRIG'],
    Price: 21,
    PowerConsumption: 6,
    PowerCapacity: 0,
    ShopLink: "https://www.tangiblewaves.com/store/p12/BEAT_DIVIDER.html",
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "DIVIDER",
    ModuleID: 20,
    Units: 1,
    Image: 'images/modules/divider.png',
    Discontinued: true,
    WikiLink: 'https:////wiki.aemodular.com/pmwiki.php/AeManual/DIVIDER',
    Categories: ['TRIG'],
    PowerConsumption: null,
    PowerCapacity: 0,
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "TRIQ164",
    ModuleID: 21,
    Units: 2,
    Image: 'images/modules/triq164.png',
    Discontinued: false,
    WikiLink: 'https:////wiki.aemodular.com/pmwiki.php/AeManual/TRIQ164',
    Categories: ['TRIG'],
    Price: 45,
    PowerConsumption: 6,
    PowerCapacity: 0,
    ShopLink: "https://www.tangiblewaves.com/store/p52/TRIQ164.html",
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "SEQ16",
    ModuleID: 22,
    Units: 4,
    Image: 'images/modules/seq16.png',
    Discontinued: false,
    WikiLink: 'https:////wiki.aemodular.com/pmwiki.php/AeManual/SEQ16',
    Categories: ['TRIG', 'CV'],
    Price: 74,
    PowerConsumption: 7,
    PowerCapacity: 0,
    ShopLink: "https://www.tangiblewaves.com/store/p41/SEQ16.html",
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "DELAY (LOFI)",
    ModuleID: 23,
    Units: 1,
    Image: 'images/modules/delay.png',
    Discontinued: false,
    WikiLink: 'https:////wiki.aemodular.com/pmwiki.php/AeManual/DELAY',
    Categories: ['EFFECT'],
    Price: 29,
    PowerConsumption: 18,
    PowerCapacity: 0,
    ShopLink: "https://www.tangiblewaves.com/store/p17/DELAY_%28LoFi%29_.html",
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "MULTIFX",
    ModuleID: 24,
    Units: 2,
    Image: 'images/modules/multifx.png',
    Discontinued: false,
    WikiLink: 'https:////wiki.aemodular.com/pmwiki.php/AeManual/MULTIFX',
    Categories: ['EFFECT'],
    Price: 95,
    PowerConsumption: 64,
    PowerCapacity: 0,
    ShopLink: "https://www.tangiblewaves.com/store/p51/MULTIFX.html",
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "SPRINGREVERB",
    ModuleID: 25,
    Units: 1,
    Image: 'images/modules/springreverb.png',
    Discontinued: false,
    WikiLink: 'https:////wiki.aemodular.com/pmwiki.php/AeManual/SPRINGREVERB',
    Categories: ['EFFECT'],
    Price: 33,
    PowerConsumption: 14,
    PowerCapacity: 0,
    ShopLink: "https://www.tangiblewaves.com/store/p40/SPRINGREVERB_%28module_only%29.html",
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "HPAMP",
    ModuleID: 26,
    Units: 1,
    Image: 'images/modules/hpamp.png',
    Discontinued: false,
    WikiLink: 'https:////wiki.aemodular.com/pmwiki.php/AeManual/HPAMP',
    Categories: ['IO'],
    Price: 30,
    PowerConsumption: 15,
    PowerCapacity: 0,
    ShopLink: "https://www.tangiblewaves.com/store/p36/HPAMP.html",
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "4I/O",
    ModuleID: 27,
    Units: 1,
    Image: 'images/modules/4io.png',
    Discontinued: false,
    WikiLink: 'https:////wiki.aemodular.com/pmwiki.php/AeManual/4IO',
    Categories: ['IO'],
    Price: 29,
    PowerConsumption: 0.2,
    PowerCapacity: 0,
    ShopLink: "https://www.tangiblewaves.com/store/p46/4I%2FO.html",
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "2SIGNALAMP",
    ModuleID: 28,
    Units: 1,
    Image: 'images/modules/2signalamp.png',
    Discontinued: false,
    WikiLink: 'https:////wiki.aemodular.com/pmwiki.php/AeManual/2SIGNALAMP',
    Categories: ['UTILITY'],
    Price: 22,
    PowerConsumption: 2,
    PowerCapacity: 0,
    ShopLink: "https://www.tangiblewaves.com/store/p43/2SIGNALAMP.html",
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "RACKLINK",
    ModuleID: 29,
    Units: 1,
    Image: 'images/modules/racklink.png',
    Discontinued: false,
    WikiLink: 'https:////wiki.aemodular.com/pmwiki.php/AeManual/RACKLINK',
    Categories: ['UTILITY'],
    Price: 35,
    PowerConsumption: 0,
    PowerCapacity: 0,
    ShopLink: "https://www.tangiblewaves.com/store/p63/RACKLINK_kit_%282_modules___cable%29.html",
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "USBPOWER",
    ModuleID: 30,
    Units: 1,
    Image: 'images/modules/usbpower.png',
    Discontinued: false,
    WikiLink: 'https:////wiki.aemodular.com/pmwiki.php/AeManual/USBPOWER',
    Categories: ['UTILITY'],
    Price: 17,
    PowerConsumption: 0,
    PowerCapacity: 0,
    ShopLink: "https://www.tangiblewaves.com/store/p44/USBPOWER.html",
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "OR2x4",
    ModuleID: 31,
    Units: 1,
    Image: 'images/modules/or2x4.png',
    Discontinued: false,
    WikiLink: 'https:////wiki.aemodular.com/pmwiki.php/AeManual/OR2x4',
    Categories: ['TRIGGER'],
    Price: 22,
    PowerConsumption: 2,
    PowerCapacity: 0,
    ShopLink: "https://www.tangiblewaves.com/store/p70/OR2x4.html",
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "STEP10",
    ModuleID: 32,
    Units: 1,
    Image: 'images/modules/step10.png',
    Discontinued: true,
    WikiLink: 'https:////wiki.aemodular.com/pmwiki.php/AeManual/STEP10',
    Categories: ['TRIGGER'],
    PowerConsumption: null,
    PowerCapacity: 0,
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "ENV",
    ModuleID: 33,
    Units: 1,
    Image: 'images/modules/env.png',
    Discontinued: true,
    WikiLink: 'https:////wiki.aemodular.com/pmwiki.php/AeManual/ENV',
    Categories: ['CV'],
    PowerConsumption: null,
    PowerCapacity: 0,
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "2OSC",
    ModuleID: 34,
    Units: 1,
    Image: 'images/modules/2osc.png',
    Discontinued: true,
    WikiLink: 'https:////wiki.aemodular.com/pmwiki.php/AeManual/2OSC',
    Categories: ['SOUND', 'CV'],
    PowerConsumption: null,
    PowerCapacity: 0,
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "KICK",
    ModuleID: 35,
    Units: 1,
    Image: 'images/modules/kick.png',
    Discontinued: false,
    WikiLink: 'https:////wiki.aemodular.com/pmwiki.php/AeManual/KICK',
    Categories: ['SOUND'],
    Price: 29,
    PowerConsumption: 3,
    PowerCapacity: 0,
    ShopLink: "https://www.tangiblewaves.com/store/p73/KICK.html",
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "DRUMKIT 010",
    ModuleID: 36,
    Units: 1,
    Image: 'images/modules/drumkit010.png',
    Discontinued: false,
    WikiLink: 'https:////wiki.aemodular.com/pmwiki.php/AeManual/DRUMKIT010',
    Categories: ['SOUND'],
    Price: 49,
    PowerConsumption: 3,
    PowerCapacity: 0,
    ShopLink: "https://www.tangiblewaves.com/store/p75/DRUMKIT_010.html",
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "TOPOGRAF",
    ModuleID: 37,
    Units: 2,
    Image: 'images/modules/topograf.png',
    Discontinued: false,
    WikiLink: 'https:////wiki.aemodular.com/pmwiki.php/AeManual/TOPOGRAF',
    Categories: ['TRIGGER'],
    Price: 65,
    PowerConsumption: 20,
    PowerCapacity: 0,
    ShopLink: "https://www.tangiblewaves.com/store/p76/TOPOGRAF.html",
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "NOISE v2",
    ModuleID: 38,
    Units: 1,
    Image: 'images/modules/noisev2.png',
    Discontinued: false,
    WikiLink: 'https:////wiki.aemodular.com/pmwiki.php/AeManual/NOISE',
    Categories: ['SOUND', 'CV'],
    Price: 20,
    PowerConsumption: 5,
    PowerCapacity: 0,
    ShopLink: "https://www.tangiblewaves.com/store/p7/NOISE.html",
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "SLEW/EDGE",
    ModuleID: 39,
    Units: 1,
    Image: 'images/modules/slewedge.png',
    Discontinued: false,
    WikiLink: 'https:////wiki.aemodular.com/pmwiki.php/AeManual/SLEWEDGE',
    Categories: ['CV'],
    Price: 27,
    PowerConsumption: 5,
    PowerCapacity: 0,
    ShopLink: "https://www.tangiblewaves.com/store/p84/SLEW%2FEDGE.html",
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "MM-DIV",
    ModuleID: 40,
    Units: 1,
    Image: 'images/modules/mm-div.png',
    Discontinued: false,
    WikiLink: 'https:////wiki.aemodular.com/pmwiki.php/AeManual/MM-DIV',
    Categories: ['TRIGGER'],
    Price: 29,
    PowerConsumption: 10,
    PowerCapacity: 0,
    ShopLink: "https://www.tangiblewaves.com/store/p83/MM-DIVIDER_.html",
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "NYLE FILTER (STEINER TYPE) v2",
    ModuleID: 41,
    Units: 2,
    Image: 'images/modules/nyle-v2.png',
    Discontinued: false,
    WikiLink: 'https:////wiki.aemodular.com/pmwiki.php/AeManual/NYLEFILTER',
    Categories: ['FILTER'],
    Price: 44,
    PowerConsumption: 2,
    PowerCapacity: 0,
    ShopLink: "https://www.tangiblewaves.com/store/p47/NYLE_FILTER_%28Steiner_type%29.html",
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "MASTER v4",
    ModuleID: 42,
    Units: 2,
    Image: 'images/modules/masterv4.png',
    Discontinued: false,
    WikiLink: 'https:////wiki.aemodular.com/pmwiki.php/AeManual/MASTER',
    Categories: ['IO'],
    Price: 49,
    PowerConsumption: 30,
    PowerCapacity: 800,
    ShopLink: "https://www.tangiblewaves.com/store/p4/MASTER.html",
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "POWER",
    ModuleID: 43,
    Units: 1,
    Image: 'images/modules/power.png',
    Discontinued: false,
    WikiLink: 'https:////wiki.aemodular.com/pmwiki.php/AeManual/POWER',
    Categories: ['IO'],
    Price: 29,
    PowerConsumption: 0,
    PowerCapacity: 800,
    ShopLink: "https://www.tangiblewaves.com/store/p85/POWER.html",
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "GRAINS",
    ModuleID: 44,
    Units: 1,
    Image: 'images/modules/grains.png',
    Discontinued: true,
    WikiLink: 'https:////wiki.aemodular.com/pmwiki.php/AeManual/GRAINS',
    Categories: ['SOUND'],
    Price: 37,
    PowerConsumption: 12,
    PowerCapacity: 0,
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "MS20 FILTER",
    ModuleID: 45,
    Units: 1,
    Image: 'images/modules/ms20filter.png',
    Discontinued: true,
    WikiLink: 'https:////wiki.aemodular.com/pmwiki.php/AeManual/MS20FILTER',
    Categories: ['FILTER'],
    Price: 33,
    PowerConsumption: 3,
    PowerCapacity: 0,
    ShopLink: "https://www.tangiblewaves.com/store/p87/MS20_FILTER.html",
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "SOLINA",
    ModuleID: 46,
    Units: 2,
    Image: 'images/modules/solina.png',
    Discontinued: false,
    WikiLink: 'https:////wiki.aemodular.com/pmwiki.php/AeManual/SOLINA',
    Categories: ['SOUND'],
    Price: 67,
    PowerConsumption: 11,
    PowerCapacity: 0,
    ShopLink: "https://www.tangiblewaves.com/store/p88/SOLINA.html",
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "2LFO",
    ModuleID: 47,
    Units: 1,
    Image: 'images/modules/2lfo.png',
    Discontinued: false,
    WikiLink: 'https:////wiki.aemodular.com/pmwiki.php/AeManual/2LFO',
    Categories: ['CV'],
    Price: 32,
    PowerConsumption: 5,
    PowerCapacity: 0,
    ShopLink: "https://www.tangiblewaves.com/store/p89/2LFO.html",
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "ALGODRONE",
    ModuleID: 48,
    Units: 2,
    Image: 'images/modules/algodrone.png',
    Discontinued: false,
    WikiLink: 'https:////wiki.aemodular.com/pmwiki.php/AeManual/ALGODRONE',
    Categories: ['SOUND'],
    Price: 72,
    PowerConsumption: 14,
    PowerCapacity: 0,
    ShopLink: "https://www.tangiblewaves.com/store/p90/ALGODRONE.html",
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "DIY",
    ModuleID: 49,
    Units: 1,
    Image: 'images/modules/diy.png',
    Discontinued: false,
    WikiLink: 'https:////wiki.aemodular.com/pmwiki.php/AeManual/DIY',
    Categories: ['UTILITY'],
    Price: 19,
    PowerConsumption: 0,
    PowerCapacity: 0,
    ShopLink: "https://www.tangiblewaves.com/store/p91/DIY_kit.html",
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "BRAEDBOARD",
    ModuleID: 50,
    Units: 2,
    Image: 'images/modules/braedboard.png',
    Discontinued: false,
    WikiLink: 'https:////wiki.aemodular.com/pmwiki.php/AeManual/BRAEDBOARD',
    Categories: ['UTILITY'],
    Price: 27,
    PowerConsumption: 0,
    PowerCapacity: 0,
    ShopLink: "https://www.tangiblewaves.com/store/p92/BRAEDBOARD.html",
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "2CVTOOL",
    ModuleID: 51,
    Units: 1,
    Image: 'images/modules/2cvtool.png',
    Discontinued: false,
    WikiLink: 'https:////wiki.aemodular.com/pmwiki.php/AeManual/2CVTOOL',
    Categories: ['UTILITY'],
    Price: 29,
    PowerConsumption: 2,
    PowerCapacity: 0,
    ShopLink: "https://www.tangiblewaves.com/store/p93/2CVTOOL.html",
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "POLAMIX",
    ModuleID: 52,
    Units: 1,
    Image: 'images/modules/polamix.png',
    Discontinued: false,
    WikiLink: 'https:////wiki.aemodular.com/pmwiki.php/AeManual/POLAMIX',
    Categories: ['UTILITY'],
    Price: 29,
    PowerConsumption: 2,
    PowerCapacity: 0,
    ShopLink: "https://www.tangiblewaves.com/store/p94/POLAMIX.html",
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "4BUFFER",
    ModuleID: 53,
    Units: 1,
    Image: 'images/modules/4buffer.png',
    Discontinued: false,
    WikiLink: 'https:////wiki.aemodular.com/pmwiki.php/AeManual/4BUFFER',
    Categories: ['UTILITY'],
    Price: 19,
    PowerConsumption: 1,
    PowerCapacity: 0,
    ShopLink: "https://www.tangiblewaves.com/store/p95/4BUFFER.html",
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "ADSR",
    ModuleID: 54,
    Units: 1,
    Image: 'images/modules/adsr.png',
    Discontinued: false,
    WikiLink: 'https:////wiki.aemodular.com/pmwiki.php/AeManual/ADSR',
    Categories: ['CV'],
    Price: 36,
    PowerConsumption: 10,
    PowerCapacity: 0,
    ShopLink: "https://www.tangiblewaves.com/store/p96/ADSR.html",
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "FMOS",
    ModuleID: 55,
    Units: 1,
    Image: 'images/modules/fmos.png',
    Discontinued: false,
    WikiLink: 'https:////wiki.aemodular.com/pmwiki.php/AeManual/FMOS',
    Categories: ['SOUND'],
    Price: 38,
    PowerConsumption: 30,
    PowerCapacity: 0,
    ShopLink: "https://www.tangiblewaves.com/store/p97/FMOS.html",
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "SAWVOX",
    ModuleID: 56,
    Units: 1,
    Image: 'images/modules/sawvox.png',
    Discontinued: false,
    WikiLink: 'https:////wiki.aemodular.com/pmwiki.php/AeManual/SAWVOX',
    Categories: ['SOUND'],
    Price: 38,
    PowerConsumption: 30,
    PowerCapacity: 0,
    ShopLink: "https://www.tangiblewaves.com/store/p98/SAWVOX.html",
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "QUANTIZER",
    ModuleID: 57,
    Units: 1,
    Image: 'images/modules/quantizer.png',
    Discontinued: false,
    WikiLink: 'https:////wiki.aemodular.com/pmwiki.php/AeManual/QUANTIZER',
    Categories: ['CV'],
    Price: 52,
    PowerConsumption: 15,
    PowerCapacity: 0,
    ShopLink: "https://www.tangiblewaves.com/store/p99/QUANTIZER.html",
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "SEQ8",
    ModuleID: 58,
    Units: 2,
    Image: 'images/modules/seq8.png',
    Discontinued: false,
    WikiLink: 'https:////wiki.aemodular.com/pmwiki.php/AeManual/SEQ8',
    Categories: ['CV'],
    Price: 45,
    PowerConsumption: 7,
    PowerCapacity: 0,
    ShopLink: "https://www.tangiblewaves.com/store/p100/SEQ8.html",
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "MS20 FILTER v2",
    ModuleID: 59,
    Units: 1,
    Image: 'images/modules/ms20filterv2.png',
    Discontinued: false,
    WikiLink: 'https:////wiki.aemodular.com/pmwiki.php/AeManual/MS20FILTER',
    Categories: ['FILTER'],
    Price: 33,
    PowerConsumption: 3,
    PowerCapacity: 0,
    ShopLink: "https://www.tangiblewaves.com/store/p87/MS20_FILTER.html",
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "GRAINS v2",
    ModuleID: 60,
    Units: 1,
    Image: 'images/modules/grainsv2.png',
    Discontinued: false,
    WikiLink: 'https:////wiki.aemodular.com/pmwiki.php/AeManual/GRAINS',
    Categories: ['SOUND'],
    Price: 37,
    PowerConsumption: 12,
    PowerCapacity: 0,
    ShopLink: "https://www.tangiblewaves.com/store/p86/GRAINS.html",
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "WAVETABLES",
    ModuleID: 61,
    Units: 2,
    Image: 'images/modules/wavetables.png',
    Discontinued: false,
    WikiLink: 'https://wiki.aemodular.com/pmwiki.php/AeManual/WAVETABLES',
    Categories: ['SOUND'],
    Price: 87,
    PowerConsumption: 40,
    PowerCapacity: 0,
    ShopLink: "https://www.tangiblewaves.com/store/p105/WAVETABLES.html",
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "MM33",
    ModuleID: 62,
    Units: 2,
    Image: 'images/modules/mm33.png',
    Discontinued: false,
    WikiLink: 'https://wiki.aemodular.com/pmwiki.php/AeManual/WonkyStuffmm33',
    Categories: ['UTILITY'],
    Price: 62,
    PowerConsumption: 1,
    PowerCapacity: 0,
    ShopLink: "https://wonkystuff.net/product/mm33/",
    Manufacturer: "Wonkystuff"
  },
  {
    Name: "RBSS",
    ModuleID: 63,
    Units: 1,
    Image: 'images/modules/rbss.png',
    Discontinued: false,
    WikiLink: 'https://wiki.aemodular.com/pmwiki.php/AeManual/WonkyStuffRBSS',
    Categories: ['CV'],
    Price: 39,
    PowerConsumption: 16,
    PowerCapacity: 0,
    ShopLink: "https://wonkystuff.net/product/rbss3/",
    Manufacturer: "Wonkystuff"
  },
  {
    Name: "RBSS (yellow)",
    ModuleID: 64,
    Units: 1,
    Image: 'images/modules/rbss-yellow.png',
    Discontinued: true,
    WikiLink: 'https://wiki.aemodular.com/pmwiki.php/AeManual/WonkyStuffRBSS',
    Categories: ['CV'],
    Price: 39,
    PowerConsumption: 16,
    PowerCapacity: 0,
    Manufacturer: "Wonkystuff"
  },
  {
    Name: "METER",
    ModuleID: 65,
    Units: 2,
    Image: 'images/modules/meter.png',
    Discontinued: false,
    WikiLink: 'https://wiki.aemodular.com/pmwiki.php/AeManual/METER',
    Categories: ['UTILITY'],
    Price: 48,
    PowerConsumption: 20,
    PowerCapacity: 0,
    ShopLink: "https://www.tangiblewaves.com/store/p106/METER.html",
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "PHASER",
    ModuleID: 66,
    Units: 2,
    Image: 'images/modules/phaser.png',
    Discontinued: false,
    WikiLink: 'https://wiki.aemodular.com/pmwiki.php/AeManual/PHASER',
    Categories: ['EFFECT'],
    Price: 49,
    PowerConsumption: 4,
    PowerCapacity: 0,
    ShopLink: "https://www.tangiblewaves.com/store/p111/PHASER.html",
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "MIXCONSOLE",
    ModuleID: 67,
    Units: 4,
    Image: 'images/modules/mixconsole.png',
    Discontinued: false,
    WikiLink: 'https://wiki.aemodular.com/pmwiki.php/AeManual/MIXCONSOLE',
    Categories: ['IO'],
    Price: 75,
    PowerConsumption: 28,
    PowerCapacity: 0,
    ShopLink: "https://www.tangiblewaves.com/store/p108/MIXCONSOLE.html",
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "MIXCONSOLE/3CH",
    ModuleID: 68,
    Units: 2,
    Image: 'images/modules/mixconsole3ch.png',
    Discontinued: false,
    WikiLink: 'https://wiki.aemodular.com/pmwiki.php/AeManual/MIXCONSOLE3CH',
    Categories: ['IO'],
    Price: 48,
    PowerConsumption: 15,
    PowerCapacity: 0,
    ShopLink: "https://www.tangiblewaves.com/store/p109/MIXCONSOLE_-_3CH.html",
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "4ATTMIX FADER",
    ModuleID: 69,
    Units: 2,
    Image: 'images/modules/4attmixfdr.png',
    Discontinued: false,
    WikiLink: 'https://wiki.aemodular.com/pmwiki.php/AeManual/4ATTMIXFADER',
    Categories: ['UTILITY', 'CV'],
    Price: 34,
    PowerConsumption: 2,
    PowerCapacity: 0,
    ShopLink: "https://www.tangiblewaves.com/store/p107/4ATTMIX_FADER.html",
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "DRONE38",
    ModuleID: 70,
    Units: 4,
    Image: 'images/modules/drone38.png',
    Discontinued: false,
    WikiLink: 'https://wiki.aemodular.com/pmwiki.php/AeManual/DRONE38',
    Categories: ['SOUND'],
    Price: 59,
    PowerConsumption: 6,
    PowerCapacity: 0,
    ShopLink: "https://www.tangiblewaves.com/store/p119/DRONE38.html",
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "DRONX",
    ModuleID: 71,
    Units: 4,
    Image: 'images/modules/dronx.png',
    Discontinued: false,
    WikiLink: 'https://wiki.aemodular.com/pmwiki.php/AeManual/DRONX',
    Categories: ['UTILITY', 'EFFECT'],
    Price: 70,
    PowerConsumption: 40,
    PowerCapacity: 0,
    ShopLink: "https://www.tangiblewaves.com/store/p120/DRONX.html",
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "TRIP",
    ModuleID: 72,
    Units: 1,
    Image: 'images/modules/trip.png',
    Discontinued: false,
    WikiLink: 'https://wiki.aemodular.com/pmwiki.php/AeManual/TRIP',
    Categories: ['TRIGGER'],
    Price: 35,
    PowerConsumption: 8,
    PowerCapacity: 0,
    ShopLink: "https://www.tangiblewaves.com/store/p121/TRIP.html",
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "QVCA (black)",
    ModuleID: 73,
    Units: 1,
    Image: 'images/modules/qvca-black.png',
    Discontinued: false,
    WikiLink: 'https://wiki.aemodular.com/pmwiki.php/AeManual/WonkyStuffQVCA',
    Categories: ['UTILITY'],
    Price: 45,
    PowerConsumption: 3,
    PowerCapacity: 0,
    ShopLink: "https://wonkystuff.net/product/qvca-black/",
    Manufacturer: "WonkyStuff"
  },
  {
    Name: "QVCA (red)",
    ModuleID: 74,
    Units: 1,
    Image: 'images/modules/qvca-red.png',
    Discontinued: true,
    WikiLink: 'https://wiki.aemodular.com/pmwiki.php/AeManual/WonkyStuffQVCA',
    Categories: ['UTILITY'],
    Price: 45,
    PowerConsumption: 3,
    PowerCapacity: 0,
    Manufacturer: "WonkyStuff"
  },
  {
    Name: "JOYSTICK",
    ModuleID: 75,
    Units: 1,
    Image: 'images/modules/joystick.png',
    Discontinued: false,
    WikiLink: 'https://wiki.aemodular.com/pmwiki.php/AeManual/JOYSTICK',
    Categories: ['UTILITY', 'CV'],
    Price: 0,
    PowerConsumption: 12,
    PowerCapacity: 0,
    ShopLink: "https://www.tangiblewaves.com/store/p130/JOYSTICK.html",
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "MIXCON JACKOUT",
    ModuleID: 76,
    Units: 2,
    Image: 'images/modules/mixcon-jackout.png',
    Discontinued: false,
    WikiLink: 'https://wiki.aemodular.com/pmwiki.php/AeManual/MIXCONJACKOUT',
    Categories: ['IO'],
    Price: 0,
    PowerConsumption: 0,
    PowerCapacity: 0,
    ShopLink: "https://www.tangiblewaves.com/store/p131/MIXCONSOLE_JACKOUT.html",
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "MIXCON XLROUT",
    ModuleID: 77,
    Units: 2,
    Image: 'images/modules/mixcon-xlrout.png',
    Discontinued: false,
    WikiLink: 'https://wiki.aemodular.com/pmwiki.php/AeManual/MIXCONXLROUT',
    Categories: ['IO'],
    Price: 0,
    PowerConsumption: 2,
    PowerCapacity: 0,
    ShopLink: "https://www.tangiblewaves.com/store/p132/MIXCONSOLE_XLROUT.html",
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "DEAD BAND",
    ModuleID: 78,
    Units: 1,
    Image: 'images/modules/deadband.png',
    Discontinued: false,
    WikiLink: 'https://wiki.aemodular.com/pmwiki.php/AeManual/KeuerslagerkurtDeadBand',
    Categories: ['EFFECT', 'CV'],
    Price: 40,
    PowerConsumption: 0,
    PowerCapacity: 0,
    ShopLink: "https://www.tindie.com/products/keurslagerkurt/kurts-dead-band/",
    Manufacturer: "Keurslager Kurt"
  },
  {
    Name: "QUAD BOOST",
    ModuleID: 79,
    Units: 1,
    Image: 'images/modules/quadboost.png',
    Discontinued: false,
    WikiLink: 'https://wiki.aemodular.com/pmwiki.php/AeManual/KeuerslagerkurtQuadBoost',
    Categories: ['EFFECT', 'CV'],
    Price: 35,
    PowerConsumption: 0,
    PowerCapacity: 0,
    ShopLink: "https://www.tindie.com/products/keurslagerkurt/kurts-quad-boost/",
    Manufacturer: "Keurslager Kurt"
  },
  {
    Name: "4VCA",
    ModuleID: 80,
    Units: 1,
    Image: 'images/modules/4vca.png',
    Discontinued: false,
    WikiLink: 'https://wiki.aemodular.com/pmwiki.php/AeManual/4VCA',
    Categories: ['UTILITY'],
    Price: 36,
    PowerConsumption: 8,
    PowerCapacity: 0,
    ShopLink: "https://www.tangiblewaves.com/store/p136/4VCA.html",
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "2TONE",
    ModuleID: 81,
    Units: 1,
    Image: 'images/modules/2tone.png',
    Discontinued: false,
    WikiLink: 'https://wiki.aemodular.com/pmwiki.php/AeManual/2TONE',
    Categories: ['EFFECT', 'UTILITY'],
    Price: 28,
    PowerConsumption: 1,
    PowerCapacity: 0,
    ShopLink: "https://www.tangiblewaves.com/store/p133/2TONE.html",
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "SWITCHMATRIX 4x4",
    ModuleID: 82,
    Units: 2,
    Image: 'images/modules/switchmatrix4x4.png',
    Discontinued: false,
    WikiLink: 'https://wiki.aemodular.com/pmwiki.php/AeManual/SWITCHMATRIX4x4',
    Categories: ['UTILITY'],
    Price: 60,
    PowerConsumption: 12,
    PowerCapacity: 0,
    ShopLink: "https://www.tangiblewaves.com/store/p135/SWITCHMATRIX_4x4.html",
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "VMBRIDGE",
    ModuleID: 83,
    Units: 1,
    Image: 'images/modules/vmbridge.png',
    Discontinued: false,
    WikiLink: 'https://wiki.aemodular.com/pmwiki.php/AeManual/VMBRIDGE',
    Categories: ['IO'],
    Price: 38,
    PowerConsumption: 3,
    PowerCapacity: 0,
    ShopLink: "https://www.tangiblewaves.com/store/p134/VMBRIDGE.html",
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "BIOT",
    ModuleID: 84,
    Units: 1,
    Image: 'images/modules/biot.png',
    Discontinued: false,
    WikiLink: 'https://wiki.aemodular.com/pmwiki.php/AeManual/BioT',
    Categories: ['UTILITY'],
    Price: 20,
    PowerConsumption: 0,
    PowerCapacity: 0,
    ShopLink: "https://wonkystuff.net/product/biot-kit/",
    Manufacturer: "WonkyStuff"
  },
  {
    Name: "2CVADD-HQ",
    ModuleID: 85,
    Units: 1,
    Image: 'images/modules/2cvadd-hq.png',
    Discontinued: false,
    WikiLink: 'https://wiki.aemodular.com/pmwiki.php/AeManual/2CVADD-HQ',
    Categories: ['CV'],
    Price: 33,
    PowerConsumption: 3,
    PowerCapacity: 0,
    ShopLink: "https://www.tangiblewaves.com/store/p138/2CVADD-HQ.html",
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "6MUTE",
    ModuleID: 86,
    Units: 1,
    Image: 'images/modules/6mute.png',
    Discontinued: false,
    WikiLink: 'https://wiki.aemodular.com/pmwiki.php/AeManual/6MUTE',
    Categories: ['UTILITY'],
    Price: 46,
    PowerConsumption: 8,
    PowerCapacity: 0,
    ShopLink: "https://www.tangiblewaves.com/store/p139/6MUTE.html",
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "CVADDER-HQ",
    ModuleID: 87,
    Units: 1,
    Image: 'images/modules/cvadder-hq.png',
    Discontinued: false,
    WikiLink: 'https://wiki.aemodular.com/pmwiki.php/AeManual/CVADDER-HQ',
    Categories: ['CV'],
    Price: 37,
    PowerConsumption: 8,
    PowerCapacity: 0,
    ShopLink: "https://www.tangiblewaves.com/store/p137/CVADDER-HQ.html",
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "CIRRUS",
    ModuleID: 88,
    Units: 3,
    Image: 'images/modules/cirrus.png',
    Discontinued: false,
    WikiLink: 'https://wiki.aemodular.com/pmwiki.php/AeManual/CIRRUS',
    Categories: ['SOUND', 'EFFECT'],
    Price: 142,
    PowerConsumption: 90,
    PowerCapacity: 0,
    ShopLink: "https://www.tangiblewaves.com/store/p142/CIRRUS.html",
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "STOMP I/O",
    ModuleID: 89,
    Units: 1,
    Image: 'images/modules/stomp-io.png',
    Discontinued: false,
    WikiLink: 'https://wiki.aemodular.com/pmwiki.php/AeManual/STOMPIO',
    Categories: ['IO'],
    Price: 32,
    PowerConsumption: 4,
    PowerCapacity: 0,
    ShopLink: "https://www.tangiblewaves.com/store/p140/STOMP-IO.html",
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "TUBE-VU",
    ModuleID: 90,
    Units: 3,
    Image: 'images/modules/tube-vu.png',
    Discontinued: false,
    WikiLink: 'https://wiki.aemodular.com/pmwiki.php/AeManual/TUBEVU',
    Categories: ['UTILITY'],
    Price: 69,
    PowerConsumption: 0,
    PowerCapacity: 0,
    ShopLink: "https://www.tangiblewaves.com/store/p141/TUBE-VU.html",
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "EUCLID GRID",
    ModuleID: 91,
    Units: 2,
    Image: 'images/modules/euclidgrid.png',
    Discontinued: false,
    WikiLink: 'https://wiki.aemodular.com/pmwiki.php/AeManual/KyaaEuclid',
    Categories: ['TRIGGER'],
    Price: 100,
    PowerConsumption: 2.5,
    PowerCapacity: 0,
    ShopLink: "https://kyaa.co/product/euclid-grid",
    Manufacturer: "Kyaa"
  },
  {
    Name: "FIVE STEPS",
    ModuleID: 92,
    Units: 1,
    Image: 'images/modules/kk_5steps.png',
    Discontinued: false,
    WikiLink: 'https://wiki.aemodular.com/pmwiki.php/AeManual/KeuerslagerkurtFivesteps',
    Categories: ['CV'],
    Price: 40,
    PowerConsumption: 0,
    PowerCapacity: 0,
    ShopLink: "https://www.tindie.com/products/keurslagerkurt/kurts-five-steps-arpeggiator-for-ae-modular/",
    Manufacturer: "Keurslager Kurt"
  },
  {
    Name: "THE GREAT DIVIDE",
    ModuleID: 93,
    Units: 1,
    Image: 'images/modules/kk_greatdivide.png',
    Discontinued: false,
    WikiLink: 'https://wiki.aemodular.com/pmwiki.php/AeManual/KeuerslagerkurtGreatDivide',
    Categories: ['UTILITY'],
    Price: 30,
    PowerConsumption: 0,
    PowerCapacity: 0,
    ShopLink: "https://www.tindie.com/products/keurslagerkurt/the-great-divide-2-x-r2r-module-diy-kit/",
    Manufacturer: "Keurslager Kurt"
  },
  {
    Name: "MOCO",
    ModuleID: 94,
    Units: 1,
    Image: 'images/modules/moco.png',
    Discontinued: false,
    WikiLink: 'https://wiki.aemodular.com/pmwiki.php/AeManual/WonkyStuffMoco',
    Categories: ['UTILITY'],
    Price: 40,
    PowerConsumption: 0,
    PowerCapacity: 0,
    ShopLink: "https://wonkystuff.net/product/moco-1st-edition/",
    Manufacturer: "Wonkystuff"
  },
  {
    Name: "CORE1.AE",
    ModuleID: 95,
    Units: 1,
    Image: 'images/modules/core1_ae.png',
    Discontinued: false,
    WikiLink: 'https://wiki.aemodular.com/pmwiki.php/AeManual/WonkyStuffCore1ae',
    Categories: ['UTILITY', 'SOUND', 'EFFECT'],
    Price: 40,
    PowerConsumption: 0,
    PowerCapacity: 0,
    ShopLink: "https://wonkystuff.net/product/core1-ae-v1-2-black/",
    Manufacturer: "Wonkystuff"
  },
  {
    Name: "TBD",
    ModuleID: 96,
    Units: 2,
    Image: 'images/modules/tbd.png',
    Discontinued: false,
    WikiLink: 'https://wiki.aemodular.com/pmwiki.php/AeManual/TBD',
    Categories: ['UTILITY', 'SOUND', 'EFFECT'],
    Price: 95,
    PowerConsumption: 80,
    PowerCapacity: 0,
    ShopLink: "https://www.tangiblewaves.com/store/p149/TBD.html",
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "CVSHIFTER",
    ModuleID: 97,
    Units: 1,
    Image: 'images/modules/cvshifter.png',
    Discontinued: false,
    WikiLink: 'https://wiki.aemodular.com/pmwiki.php/AeManual/CVSHIFTER',
    Categories: ['CV'],
    Price: 36,
    PowerConsumption: 12,
    PowerCapacity: 0,
    ShopLink: "https://www.tangiblewaves.com/store/p148/CVSHIFTER.html",
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "ENVFOLLOWER/EXT.IN",
    ModuleID: 98,
    Units: 1,
    Image: 'images/modules/envfollower.png',
    Discontinued: false,
    WikiLink: 'https://wiki.aemodular.com/pmwiki.php/AeManual/ENVFOLLOWER',
    Categories: ['CV', 'IO'],
    Price: 39,
    PowerConsumption: 7,
    PowerCapacity: 0,
    ShopLink: "https://www.tangiblewaves.com/store/p147/ENVFOLLOWER%2FEXT.IN.html",
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "MODULATORS",
    ModuleID: 99,
    Units: 1,
    Image: 'images/modules/modulators.png',
    Discontinued: false,
    WikiLink: 'https://wiki.aemodular.com/pmwiki.php/AeManual/MODULATORS',
    Categories: ['CV'],
    Price: 37,
    PowerConsumption: 13,
    PowerCapacity: 0,
    ShopLink: "https://www.tangiblewaves.com/store/p152/MODULATORS.html",
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "DIODE FILTER",
    ModuleID: 100,
    Units: 1,
    Image: 'images/modules/diode-filter.png',
    Discontinued: false,
    WikiLink: 'https://wiki.aemodular.com/pmwiki.php/AeManual/DIODEFILTER',
    Categories: ['NEW', 'FILTER'],
    Price: 36,
    PowerConsumption: 3,
    PowerCapacity: 0,
    ShopLink: "https://www.tangiblewaves.com/store/p151/DIODEFILTER.html",
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "XMIX",
    ModuleID: 101,
    Units: 1,
    Image: 'images/modules/xmix.png',
    Discontinued: false,
    WikiLink: 'https://wiki.aemodular.com/pmwiki.php/AeManual/XMIX',
    Categories: ['NEW', 'UTILITY'],
    Price: 32,
    PowerConsumption: 5,
    PowerCapacity: 0,
    ShopLink: "https://www.tangiblewaves.com/store/p153/XMIX.html",
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "ORNAMENT & CRIME",
    ModuleID: 102,
    Units: 2,
    Image: 'images/modules/ornament-crime.png',
    Discontinued: false,
    WikiLink: 'https://wiki.aemodular.com/pmwiki.php/AeManual/ORNAMENTCRIME',
    Categories: ['NEW', 'SOUND', 'FILTER', 'UTILITY', 'EFFECT'],
    Price: 86,
    PowerConsumption: 60,
    PowerCapacity: 0,
    ShopLink: "https://www.tangiblewaves.com/store/p154/ORNAMENT_%26_CRIME.html",
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "COMPACT MIXER",
    ModuleID: 103,
    Units: 8,
    Image: 'images/modules/compactmixer.png',
    Discontinued: false,
    WikiLink: 'https://wiki.aemodular.com/pmwiki.php/AeManual/COMPACTMIXER',
    Categories: ['NEW', 'UTILITY', 'IO'],
    Price: 118,
    PowerConsumption: 18,
    PowerCapacity: 0,
    ShopLink: "https://www.tangiblewaves.com/store/p156/COMPACTMIXER.html",
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "HEARTBEAT",
    ModuleID: 104,
    Units: 1,
    Image: 'images/modules/heartbeat.png',
    Discontinued: false,
    WikiLink: 'https://wiki.aemodular.com/pmwiki.php/AeManual/HEARTBEAT',
    Categories: ['NEW', 'TRIGGER'],
    Price: 55,
    PowerConsumption: 14,
    PowerCapacity: 0,
    ShopLink: "https://www.tangiblewaves.com/store/p157/HEARTBEAT.html",
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "HEARTBEAT EXTENDER",
    ModuleID: 105,
    Units: 1,
    Image: 'images/modules/heartbeat-extender.png',
    Discontinued: false,
    WikiLink: 'https://wiki.aemodular.com/pmwiki.php/AeManual/HEARTBEATEXTENDER',
    Categories: ['NEW', 'TRIGGER'],
    Price: 37,
    PowerConsumption: 7,
    PowerCapacity: 0,
    ShopLink: "https://www.tangiblewaves.com/store/p158/HEARTBEAT_EXTENDER.html",
    Manufacturer: "Tangible Waves"
  },
  {
    Name: "KUZMIN FILTER",
    ModuleID: 106,
    Units: 1,
    Image: 'images/modules/kuzminfilter.png',
    Discontinued: false,
    WikiLink: 'https://wiki.aemodular.com/pmwiki.php/AeManual/KUZMINFILTER',
    Categories: ['NEW', 'FILTER'],
    Price: 43,
    PowerConsumption: 2,
    PowerCapacity: 0,
    ShopLink: "https://www.tangiblewaves.com/store/p164/KUZMIN_FILTER.html",
    Manufacturer: "Tangible Waves"
  }
];
